import React, { useContext, useEffect, useRef, useState } from "react";
import SearchBar from "../../components/common/SearchBar";
import ReplayIcon from '@mui/icons-material/Replay';
import { Button, CircularProgress } from "@mui/material";
import MapFilterMenu from "./common/MapFilterMenu";
import CustomButton from "../../components/common/Button";
import useShipmentList from "./hooks/useShipmentList";
import SelectedShipmentsList from "./components/SelectedShipmentsList";
import AllShipmentsList from "./components/AllShipmentsList";
import Modal from "../../components/common/Modal";
import ModalFooter from "../../components/common/Modal/ModalFooter";
import { defaultShipmentMapFilters } from "../../constants/filters";
import FiltersList from "../../components/FiltersList";
import DiscreteSliderLabel from "./components/SliderFilter";
import { orderAgeMarks, shipmentHubStatuses } from "constants/constants";
import { orderStatusTypes, shipmentTypes } from "constants/enums";
import MapIsTypingContext from "context/MapIsTypingContext";
import { ArrowUpIcon } from "components/svg";

const days = {
  1: 1,
  2: 2,
  3: 3,
  4: 7,
  5: 14,
  6: 21,
  7: 30,
  8: 60
}
const daysReverse = {
  1: 1,
  2: 2,
  3: 3,
  7: 4,
  14: 5,
  21: 6,
  30: 7,
  60: 8
}

function ShipmentsList ({
  handleQueryChange,
  setShipmentSkip,
  fetch_shipments,
  fetch_hubs,
  fetch_merchants,
  fetch_merchant,
  setShipmentFilters,
  shipmentFilters,
  setSelectedShipmentIds,
  selectedShipmentIds,
  selectedShipments,
  setSelectedShipments,
  shipments,
  hubs,
  reloadingShipment,
  mapBounds,
  searchText,
  setSearchText,
  loading,
  totalShipmentCount,
  setFilterUsed,
  shipmentListLoading
}) {
  const [filterOpen, setFilterOpen] = useState(false);

  const {
    t,
    selectedRef,
    filterAreaOpen,
    setFilterAreaOpen,
    pinnedFilterIds,
    setPinnedFilterIds,
    handleShipmentClick,
    handleDeselectAllItems
  } = useShipmentList({
    reloadingShipment,
    setSelectedShipmentIds,
    setSelectedShipments,
    searchText,
    setSearchText,

  });

  const topDiv = useRef(null);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const { setIsInputFocused } = useContext(MapIsTypingContext);
  useEffect(() => {
    fetch_shipments({ load: false })
    setShipmentSkip(0)
  }, [mapBounds])

  // useEffect(() => {
  //   let myInterval = setInterval(
  //     () => fetch_shipments({ load: false, filters: shipmentFilters }),
  //     30000
  //   );
  //   return () => clearInterval(myInterval);
  // }, [mapBounds]);

  useEffect(() => {
    setFetchMoreLoading(false);
  }, [shipments]);

  useEffect(() => {
    setPinnedFilterIds(localStorage?.getItem("pinnedFilterIds"));
  }, []);

  useEffect(() => {
    setIsInputFocused(filterOpen)
  }, [filterOpen])

  useEffect(() => {
    localStorage?.setItem("pinnedFilterIds", pinnedFilterIds);
  }, [pinnedFilterIds]);

  function handleFilter () {
    handleQueryChange(shipmentFilters);
    fetch_shipments({
      filters: shipmentFilters,
    })  
    setFilterUsed(true)
  }

  async function handleFilterReset () {
    handleQueryChange(defaultShipmentMapFilters);
    setShipmentFilters((old) => ({ ...old, ...defaultShipmentMapFilters }));

    fetch_shipments({
      filters: defaultShipmentMapFilters,
    });

    setFilterOpen(false);
    setFilterUsed(true)

  }

  const handleScroll = () => {
      topDiv.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'  
      });
  };

  return (
    <>
      <div>
        <div className="mb-4 flex justify-between items-center space-x-3 relative">
          <div className="flex space-x-3">
            <h2 className="pageTitle">Orders</h2>
            {shipmentListLoading && <CircularProgress size={28} />}
          </div>
          <div className="flex justify-end">
            <MapFilterMenu open={filterAreaOpen} setOpen={setFilterAreaOpen} />
            <SearchBar
              value={searchText}
              onChange={setSearchText}
              placeholder={t("search_shipments")}
            />
          </div>
        </div>
        {filterAreaOpen && (
          <div
            onClick={() => setFilterOpen(true)}
            className="cursor-pointer uppercase text-blue-400 font-bold text-center bg-white py-3 rounded-md hover:bg-blue-100 text-sm"
          >
            Open All Filters
          </div>
        )}

        <div
          className={`flex flex-col space-y-3  h-[calc(100vh-100px)] ${filterAreaOpen && "h-[calc(100vh-150px)] "
            }
         overflow-y-scroll removeScroll`}
        >
          <span ref={topDiv}></span>
          <SelectedShipmentsList
            handleShipmentClick={handleShipmentClick}
            data={selectedShipments}
            selectedRef={selectedRef}
            selectedShipmentIds={selectedShipmentIds}
            handleDeselectAllItems={handleDeselectAllItems}
          />

          {!loading && (
            <AllShipmentsList
              data={shipments}
              selectedRef={selectedRef}
              handleShipmentClick={handleShipmentClick}
              selectedShipmentIds={selectedShipmentIds}
            >
              {shipments?.length > 0 && (
                <div className="w-full flex items-center justify-center">
                  <CustomButton
                    onClick={() => {
                      setFetchMoreLoading(true);
                      const skip = shipments.length || 0;
                      setShipmentSkip(skip);
                      fetch_shipments({ skip: skip, load: false });
                    }}
                  >
                    {totalShipmentCount > shipments.length
                      ? fetchMoreLoading
                        ? "Loading..."
                        : "Load More"
                      : ""}
                  </CustomButton>
                </div>
              )}
            </AllShipmentsList>
          )}

          {loading && (
            <div className="mx-auto mt-10 flex flex-col items-center justify-center">
              <CircularProgress />
            </div>
          )}
          {shipments?.length == 0 && !loading && (
            <div className="mx-auto mt-10  flex flex-col items-center justify-center">
              {t("no_shipments")}
            </div>
          )}
        </div>
          <div onClick={handleScroll} className="flex justify-center">
            <div className="bg-success rounded-md cursor-pointer">
              <ArrowUpIcon />
            </div>
          </div>
      </div>
      <Modal
        onDismiss={() => {
          setFilterOpen(false);
        }}
        hidden
        autoOpen={filterOpen}
        footer={(handleClose) => (
          <ModalFooter
            handleClose={handleClose}
            handleSubmit={handleFilter}
            handleReset={handleFilterReset}
          />
        )}
        onClose={() => { }}
        title={"Filter Orders"}
      >
        <div className="p-4">
          Age of orders
          <div>
            <DiscreteSliderLabel
              min={1}
              max={8}
              marks={orderAgeMarks}
              defaultVal={daysReverse[+shipmentFilters['shipmentReadySince']]}
              handleChange={(day) => {
                setShipmentFilters((old) => ({
                  ...old,
                  shipmentReadySince: days[day],
                }));
              }}
            />
          </div>


        </div>
        <FiltersList
          selectedFilters={shipmentFilters}
          filters={[
            {
              name: "Filter by Status",
              value: "shipmentOrderStatus",
              id: 2,
              options: orderStatusTypes,
              isMulti: true,
            },
            {
              name: "Filter by Merchant",
              value: "shipmentMerchant",
              id: 3,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (id) => {
                if (id && id != "all") {
                  return fetch_merchant({ id: id });
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery, page) =>
                  fetch_merchants({ page: page, searchQuery: searchQuery }),
            },
            {
              name: "Filter by Hub",
              value: "hubIds",
              id: 4,
              isAsync: true,
              isSearchable: true,
              getSelectedVal: (ids) => {
                if (ids && ids != "all") {
                  return hubs.filter((hub) => ids.includes(hub.value));
                } else return { value: "all", label: "All" };
              },
              loadMore: (searchQuery, page) =>
                fetch_hubs({ page, searchQuery, merchantId: shipmentFilters.shipmentMerchant }),
              cacheUniqs: [shipmentFilters.shipmentMerchant],
              isMulti: true,
            },
            {
              name: "Filter by Shipment Type",
              value: "shipmentType",
              id: 5,
              options: shipmentTypes,
              isMulti: true,
            },
            {
              name: "Filter by Hub Status",
              value: "shipmentHubOpen",
              id: 6,
              options: shipmentHubStatuses,
            },
          ]}
          handleChange={(filterVal, filterName) => {
            setShipmentFilters((old) => ({
              ...old,
              [filterName]: filterVal,
            }));
          }}
        />
      </Modal>
    </>
  );
}

export default React.memo(ShipmentsList);
