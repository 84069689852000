import { gql } from "apollo-boost";

export const GET_ALL_CITIES_FOR_FILTER = gql`
	query ($offset: Int
		$limit: Int) {
		fetchAllCities(offset: $offset
			limit: $limit) {
			value: id
			label: name
		}
	}
`;

export const GET_ALL_MERCHANTS_FOR_FILTER = gql`
	query (
		$offset: Int
		$limit: Int
		$query: String
		) {
		fetchAllMerchants(
			offset: $offset
			limit: $limit
			query: $query
		) {
			value: id
			label: name
		}
	}
`;
export const GET_MERCHANT_FOR_FILTER = gql`
	query fetchMerchant (
		$id: Int!
		) {
		fetchMerchant(
			id: $id
		) {
			value: id
			label: name
		}
	}
`;

export const GET_INVENTORY_LOGS_WITH_MERCHANT_AND_PRODUCT_ID_FOR_FILTER = gql`
	query GetInventoryLogsWithMerchantAndProductId(
		$input: CurrentInventoryFilterInput
	) {
		currentInventory(where: $input) {
			items {
				id
				serials
				merchant {
					value: id
					label: name
				}
				product {
					value: id
					label: name
				}
			}
		}
	}
`;

export const GET_CITY_FOR_FILTER = gql`
	query fetchCity (
		$id: Int!
		) {
		fetchCity(
			id: $id
		) {
			value: id
			label: name
		}
	}
`;

export const GET_ALL_HUBS_FOR_FILTER = gql`
	query (
		$offset: Int
		$limit: Int
		$merchantId: Int
		) {
			fetchAllHubs(
			offset: $offset
			limit: $limit
			merchantId: $merchantId
		) {
			value: id
			label: code
		}
	}
`;

export const GET_ALL_NATIONALITIES_FOR_FILTER = gql`
query {
	fetchAllNationalities {
		items
		totalCount
	}
}`;

export const GET_ALL_VEHICLE_MODELS_FOR_FILTER = gql`
query {
	fetchVehicleEnums {
		models
	}
}`;

export const GET_ALL_NEIGBORHOODS_FOR_FILTER = gql`
	query (
		$offset: Int
		$limit: Int
		) {
			fetchAllNeighborhoods(
			offset: $offset
			limit: $limit
		) {
			value: id
			label: name
		}
	}
`;