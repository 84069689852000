import React from "react";
import { SpeedDial } from "@mui/material";
import { SpeedDialIcon } from "@mui/material";
import { SpeedDialAction } from "@mui/material";

export default function FloatingActionMenu ({ actions }) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(prev => !prev)
  }

  return (
    <SpeedDial
      sx={{
        position: "absolute",
        bottom: "2rem",
        right: "1rem",
      }}
      ariaLabel="SpeedDial example"
      icon={<SpeedDialIcon />}
      onClick={handleClick}
      open={open}
      direction="left"
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => {
            action.handleClick();
            setOpen(false);
          }}
        />
      ))}
    </SpeedDial>
  );
}
